import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Carson Moore</h2>
        <p><a href="mailto:carsontmoore@gmail.com">carsontmoore@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Carson.  I&apos;m a cloud solution engineer and javascript developer.
        I previously worked for <a href="https://cloud.oracle.com/">Oracle</a> in that capacity and am actively looking for a new opportunity. Before I pivoted to a career in tech, I
        studied Economics at <a href="https://utexas.edu">UT Austin</a> and worked as a Master Trainer at <a href="https://24hourfitness.com">24 Hour Fitness</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Carson Moore <Link to="/">carsoncodes.io</Link>.</p>
    </section>
  </section>
);

export default SideBar;
